import React from 'react';
import { Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import './App.css'
import ControlPanel from './component/controlPanel';


const App: React.FC = () => {

  return (
    <div style={{ padding: '2vh' }}>
      <Row gutter={24}>
        <Col span={8}>
          <Card bodyStyle={{ height: '95vh' }}>
            <h1>Original</h1>
            <div id='original_chart' style={{ textAlign: 'center', padding: '1em'}}></div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ height: '95vh'}}>
            <h1>Improved </h1>
            <div id='improved_chart' style={{ textAlign: 'center', padding: '1em'}}></div>
          </Card>
        </Col>
        <Col span={8}>
          <ControlPanel></ControlPanel>
        </Col>
      </Row>
    </div>

  );
}

export default App;