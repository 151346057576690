import React, { useCallback, useState } from 'react';
import { Upload, Card, InputNumber, Button, Col, Row, Radio, Tabs } from 'antd';
import { InboxOutlined, GithubOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { post } from '../libs/requests';
import { AxiosResponse } from 'axios'

const { Dragger } = Upload;
const { TabPane } = Tabs;

const exampleChartList = [
    {
        id: 0,
        imageUrl: '/area_basic_0.png',
        svgUrl: '/area_basic_0.svg'
    },
    {
        id: 1,
        imageUrl: '/line_several_group_0.png',
        svgUrl: '/line_several_group_0.svg'
    },
    {
        id: 2,
        imageUrl: '/line_confidence_interval_0.png',
        svgUrl: '/line_confidence_interval_0.svg'
    },
    {
        id: 3,
        imageUrl: '/areachart.png',
        svgUrl: '/areachart.svg'
    },
    {
        id: 4,
        imageUrl: '/barplot_animation_start_0.png',
        svgUrl: '/barplot_animation_start_0.svg'
    },
    {
        id: 5,
        imageUrl: '/barplot_horizontal_0.png',
        svgUrl: '/barplot_horizontal_0.svg'
    }
]

const ControlPanel: React.FC = () => {
    const [originalChart, setOriginalChart] = useState('')
    const [height, setHeight] = useState(200)
    const [width, setWidth] = useState(200)
    const [maxStep, setMaxStep] = useState(500)
    const [stepDelta, setStepDelta] = useState(5)
    const [mode, setMode] = useState('upload')
    const [currentSvgUrl, setCurrentSvgUrl] = useState(exampleChartList[0].svgUrl)
    const [running, setRunning] = useState(false)

    const uploadChart = (file: Blob) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
            if (reader.result == null) {
                // TODO: Alert
                return false
            }
            setOriginalChart(reader.result as string)
        }
        return false
    }

    const shiftChartQueue = (info: UploadChangeParam) => {
        if (info.fileList.length > 1) {
            info.fileList.shift()
        }
    }

    const startImporve = useCallback(async () => {
        // Because setState is an async operation
        setRunning(true)
        let localVariable = originalChart
        if (mode === 'example') {
            if (currentSvgUrl === '') {
                alert('Please choose one example')
                return
            }
            localVariable = (await (
                await fetch(currentSvgUrl).then(
                    response => response.text()
                )))
        }
        post('/api/improve', {
            'original_chart': localVariable,
            'height': height,
            'width': width,
            'max_step': maxStep,
            'step_delta': stepDelta
        }, (response: AxiosResponse) => {
            const originalChartDiv = document.getElementById('original_chart')
            if (originalChartDiv != null) {
                originalChartDiv.innerHTML = ''
                originalChartDiv.innerHTML = localVariable
                const originalChartElement = originalChartDiv.firstElementChild as SVGElement
                const transform = originalChartElement.getElementsByTagName('g')[0].getAttribute('transform')?.split(',')
                if (transform) {
                    const x = transform[0].substring(10)
                    const y = transform[1].substring(0, transform[1].length-1)
                    originalChartElement.getElementsByTagName('g')[0].innerHTML += 
                    `<rect x="-${x}" y="-${y}" height=${height} width=${width} fill-opacity="0" stroke="black" stroke-width="1"/>`
                }
                originalChartElement.setAttribute('height',
                    String(Math.max(Number(originalChartElement.getAttribute('height')), height)))
                originalChartElement.setAttribute('width',
                    String(Math.max(Number(originalChartElement.getAttribute('width')), width)))
            }
            const improvedChartDiv = document.getElementById('improved_chart')
            if (improvedChartDiv != null) {
                improvedChartDiv.innerHTML = ''
                improvedChartDiv.innerHTML = response.data.improved_chart as string
                const improvedChartElement = improvedChartDiv.firstElementChild as Element
                improvedChartElement.setAttribute('style', 'outline: solid 1px black')
                improvedChartElement.setAttribute('height', height.toString())
                improvedChartElement.setAttribute('width', width.toString())
                improvedChartDiv.innerHTML += '<br><br>Time cost: ' + response.data.time_cost
            }
            setRunning(false)
        })
    }, [originalChart, height, width, maxStep, stepDelta, mode, currentSvgUrl])

    return (
        <Card bodyStyle={{ padding: '10px', height: '95vh' }}>
            <div style={{ textAlign: 'center' }}>
                <div className="input-block">
                    <span className="input-number-container">
                        Height: <InputNumber min={100} max={500} defaultValue={200} step={10}
                            onChange={e => { setHeight(Math.round(e as number / 10)*10) }} />
                    </span>
                    <span className="input-number-container">
                        Width: <InputNumber min={100} max={500} defaultValue={200} step={10}
                            onChange={e => { setWidth(Math.round(e as number / 10)*10) }} />
                    </span>
                </div>
                <div className="input-block">
                    <span className="input-number-container">
                        Max Steps: <InputNumber min={1} max={1000} defaultValue={500}
                            onChange={e => { setMaxStep(e as number) }} />
                    </span>
                    {/* <span className="input-number-container">
                        Step Delta: <InputNumber min={1} max={10} defaultValue={5} disabled={true}
                            onChange={e => { setStepDelta(e as number) }} />
                    </span> */}
                </div>
            </div>
            <Tabs onChange={key => {setMode(key);}} style={{ textAlign: "center" }}>
                <TabPane key="upload" tab="Upload">
                    <div className="upload_div">
                        <Dragger name="file" multiple={false} beforeUpload={uploadChart} onChange={shiftChartQueue}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Dragger>
                        <p style={{ padding: "1em", color: "red" }}>The system is not very robust. It is recommended to use examples.</p>
                    </div>
                </TabPane>
                <TabPane key="example" tab="Example">
                    <Radio.Group defaultValue={0} onChange={e => {setCurrentSvgUrl(exampleChartList[e.target.value].svgUrl)}}>
                        <Row gutter={24}>
                            {
                                exampleChartList.map(chart => {
                                    return (
                                        <Col span={12} key={chart.id}>
                                            <Card bordered={true} actions={[
                                                <Radio value={chart.id} />
                                            ]}>
                                                <img width="100%" src={process.env.PUBLIC_URL + chart.imageUrl} alt=""></img>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Radio.Group>
                </TabPane>
            </Tabs>
            <div style={{ textAlign: 'center', padding: "1vh" }}>
                <Button type="primary" shape="round" loading={running} onClick={startImporve}>Start to Improve</Button>
            </div>
            <Card style={{ margin: "1vh" }}>
                {/*<h2>TODO:</h2>*/}
                {/*<p> 1. Support bar chart and scatter chart</p>*/}
                {/*<p> 2. Use A3C model </p>*/}
                <Button target="_blank" href="https://github.com/Abingcbc/ResponsiveChartAdaptor" type="text">
                    <GithubOutlined style={{ fontSize: 20 }}/>
                </Button>
            </Card>
        </Card>
    )
}

export default ControlPanel
