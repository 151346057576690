import axios from 'axios'

axios.defaults.baseURL = 'http://abingcbc.cn:1999'

export function get(path: string, callback: Function | null) {
    axios.get(path)
        .then(
            response => {
                if (callback != null) {
                    callback(response)
                }
            })
}

export function post(path: string, data: {}, callback: Function | null) {
    axios.post(path, data)
        .then(
            response => {
                if (callback != null) {
                    callback(response)
                }
            }
        )
}